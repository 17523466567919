<template>
  <div class="events-page">
    <h2 class="title">EVENTS</h2>
    <section class="page-content">
      <ul class="tabs">
        <li
          :key="tab.value"
          v-for="tab in tabList"
          :class="{ active: currentTab === tab.value }"
          @click="currentTab = tab.value"
        >
          {{ tab.label }}
        </li>
      </ul>
      <div class="events" v-if="events.length > 0">
        <div
          @click="openLightBox(i)"
          class="event"
          :key="i"
          v-for="(event, i) in filteredEvents"
        >
          <div class="banner">
            <img
              :src="
                event.coverImage ||
                'https://media.huashan1914.com/WebUPD/huashan1914/exhibition/Miffy-1920X1080.jpg'
              "
            />
            <div class="mask" />
          </div>
          <div class="info">
            <p class="event-name">{{ event.title }}</p>
            <p class="date">Date: {{ event.date }}</p>
          </div>
        </div>
      </div>
    </section>
  </div>
  <EachEvent
    v-if="showLightBox && filteredEvents.length > 0"
    :eventData="filteredEvents[currentOpenIndex]"
    :onClose="closeLightBox"
    :currentIndex="currentOpenIndex"
    :totalLength="dataList.length"
  />
</template>

<script>
import { onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { mapState, useStore } from "vuex";
import EachEvent from "./EachEvent";

export default {
  name: "Events",
  components: { EachEvent },
  setup () {
    const store = useStore()
    const dataList = [1, 2, 3, 4, 5, 6, 6, 7];
    const tabList = [
      {
        value: 0,
        label: "全部影片",
      },
      {
        value: 1,
        label: "線上演講",
      },
      {
        value: 2,
        label: "老師訪談",
      },
      {
        value: 3,
        label: "建築調查局",
      },
    ];

    const tabs = {
      ALL: 0,
      Education: 1,
      Blog: 2,
      Others: 3,
    };

    const currentOpenIndex = ref(null);
    const currentTab = ref(tabs.ALL);
    const route = useRoute();
    const router = useRouter()
    const showLightBox = ref(false);

    const openLightBox = (id) => {
      router.replace(`/events/${id}`)
    };

    const closeLightBox = () => {
      router.replace(`/events`)

      showLightBox.value = false;
      currentOpenIndex.value = null;
    };

    watch(() => route.params.id, id => {
      if (id) {
        showLightBox.value = true;
        currentOpenIndex.value = parseInt(id);
      }
    }, { immediate: true })

    return {
      showLightBox,
      openLightBox,
      closeLightBox,
      tabList,
      currentTab,
      dataList,
      currentOpenIndex,
    };
  },
  computed: {
    ...mapState(["events"]),
    filteredEvents() {
      const tabArray = ["全部影片", "線上演講", "老師訪談", "建築調查局"];

      if (this.currentTab === 0) {
        return this.events;
      }
      
      return this.events.filter((e) => e.type == tabArray[this.currentTab]);
    },
  },
};
</script>

<style lang="scss" scoped>
.events-page {
  @include page;

  .title {
    @include page-title($white);
  }

  .page-content {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    padding: 0 80px;

    @include RWD($mediumLaptop) {
      margin-top: 50px;
    }

    @include RWD($tablet) {
      padding: 0 15px;
      margin-top: 40px;
    }
  }

  ul.tabs {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0 auto 140px;

    @include RWD($tablet) {
      width: 100%;
      margin-bottom: 60px;
    }

    li {
      width: 220px;
      color: $white;
      padding: 16px 0;
      cursor: pointer;
      text-align: center;
      font-family: "Zpix";
      font-size: 20px;
      line-height: 40px;

      @include RWD($mediumLaptop) {
        width: 170px;
        font-size: 14px;
        padding: 12px 0;
      }

      @include RWD($tablet) {
        flex: 1 1 25%;
        font-size: 15px;
        line-height: 16px;
        letter-spacing: 0.05em;
      }

      &.active {
        border-bottom: 1px solid $white;
      }
    }
  }

  .events {
    display: flex;
    flex-wrap: wrap;

    & > div {
      flex: 0 0 calc((100% - 170px) / 3);
      margin-right: 85px;
      margin-bottom: 120px;

      &:nth-child(3n) {
        margin-right: 0;
        margin-bottom: 60px;
      }
    }

    @include RWD($tablet) {
      display: block;

      & > div {
        margin-right: 0;
        margin-bottom: 60px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.event {
  &:hover {
    .mask {
      display: none;
    }
  }

  .banner {
    width: 100%;
    padding-bottom: calc(100% * 9 / 16);
    position: relative;
    margin-bottom: 20px;
    cursor: pointer;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      object-fit: cover;
    }

    .mask {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.4);

      @include RWD($tablet) {
        display: none;
      }
    }
  }

  .info {
    color: $white;

    .event-name {
      font-family: "Zpix";
      font-size: 20px;
      font-weight: 500;
      line-height: 40px;
      letter-spacing: 0.04em;
      margin: 0;

      @include RWD($mediumLaptop) {
        font-size: 14px;
      }

      @include RWD($tablet) {
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 12px;
      }
    }

    .date {
      font-family: "Zpix";
      font-size: 20px;
      font-weight: 500;
      line-height: 40px;
      letter-spacing: 0.04em;
      margin: 0;

      @include RWD($mediumLaptop) {
        font-size: 14px;
      }

      @include RWD($tablet) {
        font-size: 12px;
        line-height: 12px;
      }
    }

    .date {
      opacity: 0.7;
    }
  }
}
</style>
