<template>
  <div class="wrapper">
    <YouTube
      ref="youtube"
      :style="{ width: '100%', height: '100%' }"
      :src="videoUrl"
      :width="`100%`"
      :height="`100%`"
      @state-change="handleStageChange"
    />
    <div v-show="showMask" class="mask">
      <button
        @click="playCurrentVideo"
        :class="{ big: playButtonSize === 'big' }"
      >
        <span>PLAY</span>
      </button>
    </div>
  </div>
</template>

<script>
// import { YoutubeVue3 } from "youtube-vue3";
import YouTube from "vue3-youtube";
import { onBeforeUnmount, onMounted, ref, watch } from "vue";
export default {
  name: "YoutubeVideo",
  components: { YouTube },
  props: {
    isCurrentSlide: Boolean,
    playButtonSize: String,
    videoUrl: String,
  },
  setup(props) {
    const youtube = ref(null);
    const showMask = ref(true);

    const playCurrentVideo = () => {
      youtube.value.playVideo();
    };

    const pauseCurrentVideo = () => {
      youtube.value.pauseVideo();
    };

    const stopCurrentVideo = () => {
      youtube.value.stopVideo();
    };

    const handleStageChange = (data) => {
      const { data: state } = data;
      const playerIsPlaying = state === 1;
      showMask.value = !playerIsPlaying;
    };

    onMounted(() => {
      stopCurrentVideo();
    });

    onBeforeUnmount(() => {
      stopCurrentVideo();
    });

    watch(props, (p) => {
      if (!p.isCurrentSlide) {
        pauseCurrentVideo();
      }
    });

    return {
      showMask,
      youtube,
      playCurrentVideo,
      handleStageChange,
    };
  },
  methods: {
    // applyConfig() {
    //   this.play = Object.assign(this.play, this.temp);
    // },
    // playCurrentVideo() {
    //   this.$refs.youtube.player.playVideo();
    // },
    // stopCurrentVideo() {
    //   this.$refs.youtube.player.stopVideo();
    // },
    // pauseCurrentVideo() {
    //   this.$refs.youtube.player.pauseVideo();
    // },
    // onEnded() {
    //   this.showMask = true;
    // },
    // onPaused() {
    //   this.showMask = true;
    // },
    // onPlayed() {
    //   this.showMask = false;
    // },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: 100%;
  position: relative;

  iframe {
    width: 100%;
    height: 100%;
  }

  .mask {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);

    & > button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 110px;
      height: 110px;
      border-radius: 50%;
      border: 1px solid $white;

      &.big {
        width: 230px;
        height: 230px;

        @include RWD($tablet) {
          width: 120px;
          height: 120px;
        }
      }

      & > span {
        color: $white;
        font-family: "Zpix";
        font-size: 20px;

        @include RWD($tablet) {
          font-size: 14px;
        }
      }
    }
  }
}
</style>