<template>
  <teleport to="#portal-target">
    <div class="each-event-lightbox">
      <div class="box">
        <div class="area top">
          <button @click="onClose" class="close-button">
            <img src="@/images/icons/close-icon.svg" />
          </button>
          <div class="main-content">
            <section ref="leftRef" class="left">
              <div class="info">
                <div class="event-title">
                  <span class="tag">{{ eventData.type }}</span>
                  <p class="title" v-html="eventData.title"></p>
                </div>
                <div v-if="media === 'mobile'" class="video mobile">
                  <YoutubeVideo
                    :is-current-slide="true"
                    :videoUrl="videoUrl"
                    playButtonSize="big"
                  />
                </div>
                <div class="share">
                  <div class="content">
                    <p>Share the video</p>
                    <ul>
                      <li>
                        <button>
                          <img src="@/images/icons/facebookIcon.svg" />
                        </button>
                      </li>
                      <li>
                        <button>
                          <img src="@/images/icons/twitter.svg" />
                        </button>
                      </li>
                      <li>
                        <button>
                          <img src="@/images/icons/pintrest.svg" />
                        </button>
                      </li>
                      <li>
                        <button><img src="@/images/icons/tumblr.svg" /></button>
                      </li>
                      <li>
                        <button>
                          <img src="@/images/icons/linkIcon.svg" />
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div v-if="media === 'desktop'" class="video">
                <YoutubeVideo
                  :is-current-slide="true"
                  playButtonSize="big"
                  :videoUrl="videoUrl"
                />
              </div>
            </section>
            <section
              class="right"
              :style="{
                height: media === 'mobile' ? '240px' : `${rightHeight}px`,
              }"
            >
              <EventInfoBox
                :key="JSON.stringify(eventData)"
                :eventData="eventData"
              />
            </section>
          </div>
        </div>
        <div class="area bottom">
          <p class="title">SEE RELEVANT EVENTS</p>
          <div class="carousel">
            <Carousel
              v-if="relevantEvents.length >= 3"
              :settings="settings"
              @update:modelValue="handleUpdate"
            >
              <Slide v-for="{ id, image } in relevantEvents" :key="id">
                <img
                  class="carousel__item"
                  :src="image"
                  alt="event_image"
                  @click="handleClickCard(id)"
                />
              </Slide>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import {
  computed,
  inject,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  ref,
} from "vue";
import { mapState } from "vuex";
import { useRouter } from 'vue-router'
import EventInfoBox from "@/components/atoms/EventInfoBox";
import YoutubeVideo from "@/components/molecules/YoutubeVideo";
import { Carousel, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default {
  name: "EachEvent",
  components: { YoutubeVideo, EventInfoBox, Carousel, Slide },
  props: {
    currentIndex: Number,
    totalLength: Number,
    eventData: Object,
    onClose: Function,
  },
  // carousel settings
  data: () => ({
    settings: {
      itemsToShow: 2.5,
      wrapAround: true,
      snapAlign: "center",
      autoplay: 4000,
    },
  }),
  setup(props) {
    const media = inject("media");
    const router = useRouter()
    const videoUrl = computed(() => props.eventData.link)
    const { onClose } = props;

    const leftRef = ref(null);
    const rightHeight = computed(() => {
      if (!leftRef.value) return 0;
      return leftRef.value.offsetHeight;
    });

    onMounted(() => {
      document.body.style.overflow = "hidden";
    });

    onUnmounted(() => {
      document.body.style.overflow = "auto";
    });

    const updateIsUpdated = ref(true);
    const timeout = ref(null);
    // prevent carousel drag event, trigger redirect
    const handleUpdate = () => {
      updateIsUpdated.value = true;

      timeout.value = setTimeout(() => {
        updateIsUpdated.value = false;
      }, 10);
    };

    onBeforeUnmount(() => {
      if (timeout.value) {
        clearTimeout(timeout);
      }
    });

    const handleClickCard = (id) => {
      if (!updateIsUpdated.value) {
        router.replace(`/events/${id}`)
      }
    };

    return {
      media,
      videoUrl,
      leftRef,
      rightHeight,
      onClose,
      handleUpdate,
      handleClickCard,
    };
  },
  computed: {
    ...mapState(["events"]),
    relevantEvents() {
      let result = this.events.reduce((acc, el, idx) => {
        if (
          el.type === this.eventData.type &&
          el.event_id !== this.eventData.event_id
        ) {
          return [
            ...acc,
            {
              id: idx,
              image:
                el.coverImage ||
                "https://media.huashan1914.com/WebUPD/huashan1914/exhibition/Miffy-1920X1080.jpg",
            },
          ];
        }

        return acc;
      }, []);

      if (result && result.length !== 0) {
        recursiveAddEvent();
      }

      function recursiveAddEvent() {
        if (result.length <= 6) {
          result = result.concat(result);
          recursiveAddEvent()
        }
      }

      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.each-event-lightbox {
  @include modal;
  background-color: rgba(0, 0, 0, 0.9);
  overflow: auto;

  .box {
    display: flex;
    align-self: start;
    flex-direction: column;
    width: 100%;
    margin-bottom: 150px;
    padding: 12px;

    @include RWD($tablet) {
      margin-bottom: 50px;
    }

    .area.top {
      width: 100%;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;

      @include RWD($tablet) {
        margin-bottom: 40px;
        min-height: unset;
      }

      .close-button {
        align-self: flex-end;
        margin-bottom: 20px;
        margin-top: 70px;
        margin-right: 70px;
        color: white;

        img {
          width: 100%;
        }

        @include RWD($mediumLaptop) {
          width: 60px;
          height: 60px;
          margin-top: 45px;
          margin-right: 45px;
        }

        @include RWD($tablet) {
          width: 50px;
          height: 50px;
          margin-top: 0;
          margin-right: 0;
        }
      }

      .main-content {
        border: 1px solid $white;
        align-self: center;
        width: 1600px;
        max-width: calc(100vw - 150px);
        margin: 30px 0 80px;

        @include RWD($tablet) {
          width: 100%;
          max-width: unset;
          margin: 20px 0 50px;
        }
      }
    }

    .area.bottom {
      .title {
        text-align: center;
        font-family: "Red Rose";
        font-size: 80px;
        color: $white;
        margin-bottom: 70px;

        @include RWD($mediumLaptop) {
          font-size: 74px;
        }

        @include RWD($tablet) {
          font-size: 24px;
          line-height: 30px;
          margin-bottom: 30px;
        }
      }
    }
  }
}

.main-content {
  display: flex;
  align-items: flex-start;
  background-color: $black;

  @include RWD($tablet) {
    display: block;
    border: 1px solid $white;
  }

  & > .left {
    flex: 1 1 70%;
    border-right: 1px solid $white;

    @include RWD($tablet) {
      display: block;
      border: none;
    }

    .info {
      display: flex;

      @include RWD($tablet) {
        display: block;
      }

      .event-title {
        flex: 1 1 67%;
        padding: 30px;
        color: $white;
        border-right: 1px solid $white;

        @include RWD($mediumLaptop) {
          padding: 20px;
        }

        @include RWD($tablet) {
          display: block;
          padding: 12px;
          border-right: none;
          border-bottom: 1px solid $white;
        }

        .tag {
          font-family: "Zpix";
          border: 1px solid $white;
          padding: 10px 30px;
          font-size: 18px;

          @include RWD($mediumLaptop) {
            font-size: 12px;
          }

          @include RWD($tablet) {
            font-size: 14px;
          }
        }

        .title {
          font-family: "Zpix";
          font-size: 24px;
          line-height: 40px;
          letter-spacing: 0.04em;
          margin: 20px 0 0;

          @include RWD($tablet) {
            font-size: 18px;
          }

          @include RWD($tablet) {
            font-size: 16px;
            line-height: 16px;
          }
        }
      }

      .share {
        flex: 1 0 33%;
        padding: 0 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        @include RWD($tablet) {
          padding: 12px;
          justify-content: flex-start;
          border-bottom: 1px solid $white;
        }

        .content {
          @include RWD($tablet) {
            width: 100%;
          }

          p {
            font-family: "Red Rose";
            font-size: 20px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.04em;
            color: $white;

            @include RWD($mediumLaptop) {
              font-size: 14px;
            }

            @include RWD($tablet) {
              font-size: 14px;
            }
          }

          ul {
            display: flex;
            list-style: none;
            padding: 0;
            margin-bottom: 0;

            @include RWD($tablet) {
              justify-content: space-between;
            }

            li {
              font-size: 0;
              & + li {
                margin-left: 40px;
              }

              button {
                padding: 0;
                width: 18px;
                height: 18px;
                font-size: 0;

                & > img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                }

                @include RWD($mediumLaptop) {
                  width: 15px;
                  height: 15px;
                }
              }
            }
          }
        }
      }

      .carousel {
        width: 100%;
      }
    }

    .video {
      width: 100%;
      padding-bottom: calc(100% / 16 * 9);
      border-top: 1px solid $white;
      position: relative;
      overflow: hidden;

      & > div {
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .video.mobile {
      border-top: none;
      border-bottom: 1px solid $white;
    }
  }

  & > .right {
    flex: 1 1 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 35px;

    @include RWD($tablet) {
      padding: 0 12px;
    }
  }
}

.carousel__item {
  width: 90%;
}

.carousel__slide > .carousel__item {
  transform: scale(1);
  opacity: 0.5;
  transition: 0.5s;
}
.carousel__slide--visible > .carousel__item {
  opacity: 1;
  transform: rotateY(0);
}
.carousel__slide--next > .carousel__item {
  transform: scale(0.9) translate(-10px);
}
.carousel__slide--prev > .carousel__item {
  transform: scale(0.9) translate(10px);
}
.carousel__slide--active > .carousel__item {
  transform: scale(1.1);
}
</style>
